import React, { createContext, useState,useEffect } from 'react'
import { useServices,constants} from 'cng-web-lib'
import { FileForUserGetUserDetails,FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import PartyConfigApiUrls from 'src/apiUrls/PartyConfigApiUrls'
 
const {
    filter: {EQUAL}
  } = constants
 
 
const FileForUserContext = createContext()
 
function FileForUserProvider({ children }){
    const [fileForUserValue, setFileForUserValue] = useState({})
    const [partyConfigFetched, setPartyConfigFetched] = useState(false)
    const { securedSendRequest,fetchRecords } = useServices();
    const [user, setUser] = useState([]);
 
    useEffect(() => {
      let fileForUserDetails = FileForUserGetUserDetails();
      fetchUser(setUser, securedSendRequest, fileForUserDetails);
    }, []);
 
    // Set UI Preference in session
    useEffect(() => {
      if (user.uiPreference && user.uiPreference.length > 0) {
        sessionStorage.setItem('uiPreference', JSON.stringify(user.uiPreference))
      }
 
      sessionStorage.setItem('nangUserProfile', JSON.stringify(user))
 
      fetchRecords.execute(
        PartyConfigApiUrls.GET,
        {
          filters: [
            {
              field: 'partyId',
              operator: EQUAL,
              value: FileForUserGetPartyId()
            }
          ]
        },
        (res) => {
          if(res.content.length>0){
            sessionStorage.setItem('nangPartyConfig', JSON.stringify(res.content))
          }
          setPartyConfigFetched(true)
        });
    }, [user])
 
    return (
        <FileForUserContext.Provider value={{ setFileForUserValue, fileForUserValue, setPartyConfigFetched, partyConfigFetched}}>
        { children }
        </FileForUserContext.Provider>
    )
}
 
export default FileForUserContext
export { FileForUserProvider }